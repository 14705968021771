@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.custom-carousel-thumb {
  border: 1px solid rgb(18, 161, 18);
  border-radius: 100px;
}
.custom-carousel-thumb.selected {
  border-width: 2px;
}
